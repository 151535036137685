
import {Options, Vue} from 'vue-class-component';
import SlideI18nText from "@/components/SlideI18nText.vue";
import {MetaLocales} from "@/types/Lang";
import MetaImage from "@/components/MetaImage.vue";
import {configData} from "@/config";

@Options<AboutUs>({
  components: {MetaImage, SlideI18nText},
  props: {}
})
export default class AboutUs extends Vue {
  MetaLocales = MetaLocales;
  configData = configData;
}
